import moment from 'moment/dist/moment';
import isNumber from 'lodash/isNumber';

const YYYYMMDD = 'YYYY/MM/DD';

export const isDate = date => date && String(new Date(date || '')) !== 'Invalid Date';
export const formatDate = (date, format = YYYYMMDD) => {
  if (moment.isMoment(date)) {
    return date.format(format);
  }
  return (isDate(date) && !isNumber(date) ? moment(date).format(format) : date || '');
};

export const changeDate = (targetDate, days) => {
  if (!isDate(targetDate)) { return; }
  const date = new Date(targetDate);
  date.setDate(date.getDate() + days);
  return date;
};

export const toMoment = date => {
  if (Array.isArray(date)) {
    const momentDate = date.map(d => (isDate(d) && !moment.isMoment(d) ? moment(new Date(d)) : d));

    [date[0], date[1]] = momentDate;
    return date;
  }
  return isDate(date) ? moment(date) : date;
};
