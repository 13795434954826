import React from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import { t } from 'locale/i18n';
import { Tooltip } from 'antd';

// this inst Select, just is StateManager of React-Select
class SelectBE extends Select {
  componentDidMount() {
    const {
      select,
    } = this;
    select.openMenu = function (focusOption) {
      const { selectValue, isFocused } = this.state;
      const menuOptions = this.buildMenuOptions(this.props, selectValue);
      const { isMulti, defaultFocusValue } = this.props;
      let openAtIndex = focusOption === 'first' ? 0 : menuOptions.focusable.length - 1;

      if (!isMulti) {
        const selectedIndex = menuOptions.focusable.findIndex(opt => opt.value === get(selectValue[0], 'value'));
        if (selectedIndex > -1) {
          openAtIndex = selectedIndex;
        } else if (defaultFocusValue) {
          openAtIndex = Math.max(menuOptions.focusable.findIndex(opt => opt.value === defaultFocusValue), 0);
        }
      }

      // only scroll if the menu isn't already open
      this.scrollToFocusedOptionOnUpdate = !(isFocused && this.menuListRef);
      this.inputIsHiddenAfterUpdate = false;

      this.setState({
        menuOptions,
        focusedValue: null,
        focusedOption: menuOptions.focusable[openAtIndex],
      }, () => {
        this.onMenuOpen();
        this.announceAriaLiveContext({ event: 'menu' });
        setTimeout(() => {
          if (this.props.menuRef && this.props.menuRef.current) {
            this.props.menuRef.current.scrollToItem(openAtIndex + 4);
          }
        });
      });
    };
  }
}

export default React.forwardRef((props, ref) => (
  <SelectBE
    placeholder={t('common.placeholders.select')}
    noOptionsMessage={() => t('common.placeholders.select_no_options')}
    ref={ref}
    {...props}
  />
));

export const SelectFixed = React.forwardRef((props, ref) => (
  <SelectBE
    placeholder={t('common.placeholders.select')}
    noOptionsMessage={() => t('common.placeholders.select_no_options')}
    ref={ref}
    menuPlacement='auto'
    menuPortalTarget={document.body}
    menuShouldScrollIntoView={false}
    closeMenuOnScroll={function (e) {
      // eslint-disable-next-line react/no-this-in-sfc
      if (this.menuIsOpen) {
        if (e.target instanceof HTMLElement) {
          if (e.target.parentElement.className.includes('-menu')) {
            e.target.focus();
          } else {
            window.focus();
            if (document.activeElement) {
              document.activeElement.blur();
            }
          }
        } else if (e.target instanceof Document) {
          window.focus();
          if (document.activeElement) {
            document.activeElement.blur();
          }
        }
      }
    }}
    {...{ ...props, menuPosition: props.menuPosition || 'fixed' }}
  />
));

export const SelectWithTooltip = props => (
  <Tooltip title={props.value?.label}>
    <Select {...props} />
    <span className='d-none' />
  </Tooltip>
);
