import React, { useState, useEffect } from 'react';
// import { toDataURL } from '../../utils/imageHelper';

const ImageVersion = ({
  version,
  src,
  ...rest
}) => {
  const [correctUrl, setCorrectUrl] = useState(src?.replace('original', version));
  // const [dataUrl, setDataUrl] = useState();

  useEffect(() => {
    // setDataUrl();
    setCorrectUrl(src?.replace('original', version));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <img
      alt={`default-alt-${version}`}
      {...rest}
      // src={dataUrl || correctUrl}
      src={correctUrl}
      onError={() => setCorrectUrl(src)}
      // onLoad={() => toDataURL(correctUrl, result => setDataUrl(result))}
    />
  );
};

export default ImageVersion;
