import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { debounce, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import NewUserAvatar from '../UIKit/NewUserAvatar';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import defaultLogo from '../../../app/assets/images/lp/index/logo.png';
import { styles } from './styles';
import { LoadingGrid } from '../common';
import { useOutsideClick } from '../../utils/customHooks';
import { buildSubmitFilterGroupFormData } from '../../utils/reportUtils';

const Search = ({
  placeholder, currentUser = {}, fetchEmployees, cleanStore, employees = [], isFetching, onClickName,
}) => {
  const [t] = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(!isMobile);
  const [isFocus, setIsForcus] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showLoading, setShowLoading] = useState(isFetching);
  const previousRef = useRef();

  const toggleOpen = () => setIsOpen(prevState => !prevState);

  const onSubmit = () => {
    if (isOpen) {
      window.location = `/admin/employees?q=${searchText}`;
    } else {
      toggleOpen();
    }
  };

  const search = useCallback(debounce(text => {
    previousRef.current = text;
    fetchEmployees({
      params: {
        name_filter: text,
        fields: ['employee_number^8', 'email^8', 'full_name^7'],
        filter_group: buildSubmitFilterGroupFormData({ filterGroup: currentUser?.filterData }),
        per_page: 5,
      },
    });
  }, 500), [currentUser?.filterData]);

  const onFocus = () => setIsForcus(true);

  const searchRef = useRef();
  useOutsideClick(searchRef, () => setIsForcus(false));

  const onChange = useCallback(e => {
    if (previousRef.current !== e.target.value) {
      setShowLoading(true);
      search(e.target.value);
      setSearchText(e.target.value);
      setIsFirstTime(false);
    }
  }, [search]);

  const onKeyDown = e => {
    onChange(e);
    e.which === 13 && onSubmit();
  };

  useEffect(() => {
    setShowLoading(isFetching);
  }, [isFetching]);

  useEffect(() => () => {
    cleanStore();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const Logo = () => {
    if (!isEmpty(currentUser)) {
      return (
        <img
          className='be-navbar--item be-navbar--brand-logo'
          src={
            currentUser.companyLogoUrl
            || currentUser?.partnerLogoUrl
            || defaultLogo
          }
          alt='BetterEngage logo'
        />
      );
    }
  };

  const Name = () => (
    <div className='username small px-2 color-black' onClick={onClickName}>
      {currentUser ? currentUser.fullName : ''}
    </div>
  );

  const Employees = () => (
    <div className='employees'>
      {employees.map(({
        image_url, full_name, id, email, employee_number,
      }) => (
        <div className='employee-wrap'>
          <a href={`/management/employees/${id}`} className='employee' key={`employee-${id}`} target='_blank' rel='noreferrer'>
            <NewUserAvatar url={image_url} size={32} alt={full_name} />
            <div className='info'>
              <div className='fullname'>{full_name}</div>
              <div>{`${employee_number}/${email}`}</div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );

  const result = useMemo(() => {
    if (!isFocus || isFirstTime) {
      return <></>;
    }

    return (
      <div className='header-employee-search-result'>
        {showLoading ? (
          <LoadingGrid ldsColorClass='lds-color-gray' bgColorClass='h-100' />
        ) : (
          <div>
            {isEmpty(employees)
              ? <div className='font-size-12 employee-search-result-no-record'>{t('sidebar.no_record')}</div>
              : <Employees />}
          </div>
        )}
        <div className='link-to-employe-search'>
          <div className='font-12 color-black mb-1'>{t('sidebar.search_description')}</div>
          <a
            className='font-size-12 color-blue py-2 text-decoration-none'
            href={`/admin/employees?q=${searchText}`}
          >
            {t('sidebar.see_more')}
          </a>
        </div>
      </div>
    );
  }, [employees, showLoading, isFocus, searchText]);

  return (
    <div className={styles.main} ref={searchRef}>
      {isMobile ? (
        <Mobile {...{
          searchText,
          isOpen,
          placeholder,
          onKeyDown,
          onSubmit,
          onChange,
          onBlur: () => {
            toggleOpen();
          },
          onFocus,
          Logo,
          isViewAs: currentUser?.isViewAs,
          fullName: currentUser?.fullName,
          t,
          companyName: currentUser?.companyName,
          isAdmin: currentUser?.['directorOrSysadmin?'],
          notificationCounter: currentUser?.notificationCounter,
        }}
        />
      ) : (
        <Desktop {...{
          searchText,
          placeholder,
          onKeyDown,
          onSubmit,
          onChange,
          onFocus,
          isFocus,
          Logo,
          Name,
          isAdmin: currentUser?.['directorOrSysadmin?'],
          notificationCounter: currentUser?.notificationCounter,
          isViewAs: currentUser?.isViewAs,
          fullName: currentUser?.fullName,
          t,
          companyName: currentUser?.companyName,
        }}
        />
      )}
      {result}
    </div>
  );
};

export default Search;
