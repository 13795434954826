import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSearch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { styles } from './styles';

export const Mobile = ({
  searchText, isOpen, placeholder, onKeyDown, onSubmit, onChange, Logo, onBlur, onFocus,
  isAdmin, notificationCounter,
}) => (
  <div className={styles.mobile(isOpen)}>
    {!isOpen && <a href='/admin'>{Logo()}</a>}
    <span className='search-btn'>
      <FontAwesomeIcon
        onClick={onSubmit}
        icon={faSearch}
      />
    </span>
    {isAdmin && (
      <a href='/admin/logs' className='admin-log'>
        <FontAwesomeIcon icon={faBell} className={classNames('pointer', { active: notificationCounter > 0 })} />
        {notificationCounter > 0 && <div className='notification-counter'>{notificationCounter}</div>}
      </a>
    )}
    <span className='input'>
      <input value={searchText} onKeyDown={onKeyDown} onChange={onChange} onBlur={onBlur} placeholder={placeholder} onFocus={onFocus} />
    </span>
  </div>
);
