/* eslint-disable import/no-cycle */

import { fork, all } from 'redux-saga/effects';
import searchSaga from '../../../shared-libs/containers/Search/sagas';

const sagas = [
  ...searchSaga,
];

function* globalSagas() {
  const globalSagasForks = sagas.map(saga => fork(saga));

  yield all([...globalSagasForks]);
}

export default globalSagas;
