/* eslint sort-keys: "error" */
import { css } from 'emotion';

export const COLORS = {
  alabaster: '#FCFCFC',
  amethystSmoke: '#9589a8',
  antiFlashWhite: '#F1F3F4',
  azure_radiance: '#007BFF',
  baleBlue: '#CFE3FA',
  barberry: '#EBD619',
  beActiveDarkColor: '#52566C',
  beBlackColor: '#182C33',
  beGrayAlto: '#DADADA',
  blue: '#007BFF',
  blueAnakiwa: '#B1D7FF',
  blueDodger: '#3597FC',
  blueDodger2: '#3497FD',
  blueFrenchPass: '#BADCFF',
  blueMalibu: '#83BBF7',
  bluePattens: '#D4ECFF',
  blueScience: '#0069D9',
  blueShipCove: '#7398BF',
  bridesmaid: '#FEEDE9',
  butteryWhite: '#FFFDEB',
  cornField: '#f8f8be',
  cornflowerBlue: '#262DB9',
  cornflowerLilac: '#FFA4A4',
  curiousBlue: '#22A8E1',
  dark: '#333',
  dimGray: '#6F6F6F',
  disabledColor: '#E9ECEF',
  ebonyClay: '#2B2E43',
  eucalyptus: '#28A745',
  frenchPass: '#BADCFF',
  frenchPass2: '#C2E0FE',
  frenchRose: '#F0426C',
  ghostWhite: '#F9F9F9',
  gray: '#A3A6B4',
  grayAlto: '#D5D5D5',
  grayAthens: '#F7F7FA',
  grayAthens2: '#FCFCFD',
  grayBombay: '#B1B3BF',
  grayFrench: '#C5C6CE',
  grayGallery: '#ECECEC',
  grayGallery2: '#EFEFEF',
  grayGallery3: '#EDEDED',
  grayGeyser: '#dee2e6',
  grayLavender: '#b5b9d4',
  graySantas: '#A4A6B4',
  hoverRow: 'rgba(0, 0, 0, 0.075)',
  jellyBean: '#2889A7',
  karry: '#FFE9D4',
  kimberly: '#7076AC',
  light: '#F8F9FA',
  lighterViolet: '#51566E',
  lilyWhite: '#E8F7FF',
  mantis: '#92CE6E',
  mercury: '#E4E4E4',
  niagara: '#0CA788',
  onahau: '#D4F0FF',
  onahau2: '#C5E1FF',
  osloGray: '#878991',
  paleSky: '#6C757D',
  pattensBlue: '#D9ECFF',
  pattensBlue2: '#D6EAFF',
  persianRose: '#FF228C',
  poloBlue: '#83A6C9',
  provincialPink: '#FDEEE9',
  red: '#EB5326',
  redPomegranate: '#E8491A',
  sanMarino: '#556FB5',
  seaBuckthorn: '#FBA82B',
  seashellPeach: '#FFF7EF',
  selago: '#E4E8FB',
  silver: '#cccccc',
  silver2: '#BEBEBE',
  solitude: '#E5EEF8',
  solitude2: '#E4F2FF',
  solitude3: '#E8F3FF',
  stTropaz: '#2853A7',
  tacao: '#F29F84',
  texas: '#f8f898',
  titanWhite: '#FCFCFF',
  tradewind: '#60B5B7',
  watusi: '#FFE2D6',
  whipser: '#F1EFF5',
  whipser2: '#F1F1F8',
  whipser3: '#EFF0F6',
  white: '#FFF',
  whiteConcrete: '#F2F2F2',
  whiteLilac: '#EFEFF8',
  whiteSmoke: '#F5F6FA',
  whiteSmokeBackgroundColor: '#F2F3F7',
  whiteWildSand: '#F4F4F4',
  wildSand: '#F6F6F6',
  yellow: '#FFCD56',
  yellowLightning: '#FFBF1B',
  yellowNotice: '#F8F8BE',
  yellowNoticeHover: '#F8F898',
};

// _xx is percent
// 1a, 40, 4D is hex code of percent
// blue dodger 30% is #3597FC4D (3597FC and 4D)
export const OPACITY_HEX = {
  _10: '1a',
  _25: '40',
  _30: '4D',
  _80: 'CC',
};

export const DATE_PATTERN = /^[12]\d{3}[-/](0?[1-9]|1[0-2])[-/]([12][0-9]|3[01]|0?[1-9])$/;
export const EMAIL_FORMAT = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const TIME_END_OF_DAY = '23:59';
export const TOTAL_MINUTES_OF_DAY = 1440; // 24 * 60
export const boxShadow = 'box-shadow: 0px 0px 10px #A3A6B457;';
export const SRC_HREF_CONTAIN_PATTERN = /(src|href)=(["'])(.*?)["']/;
export const PHONE_PATTERN = /\(?([0-9]{2,4})\)?([-ー.。 ]{1})([0-9]{3,4})([-ー.。 ]{1})([0-9]{3,4})/;
export const PASSWORD_FORMAT = /^(?=.{12,})(?=.*\d)(?=.*[a-z])/;
export const EMPLOYEE_ID = /employee_id_\d+/;

export const fileTypesAccept = [
  '.csv',
  '.xlsx',
  '.xls',
  '.xlsm',
];

export const acceptedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  '.heic',
  '.heif',
  'application/pdf',
  'text/csv',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-powerpoint.presentation.macroenabled.12',
  'application/vnd.ms-excel',
  'application/msword',
];

export const acceptedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  '.heic',
  '.heif',
];

export const retireStatus = ['retire', 'fired'];

export const fileSizeMax = 1024 * 1024 * 10; // 10 MB
export const fileSizeMax20Mb = 1024 * 1024 * 20; // 20 MB

export const leaveStatuses = ['absence', 'maternity_leave', 'family_leave', 'nursing_leave'];

export const ckClassName = 'ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred';

export const fixedRangeTypes = [
  'this_month', 'last_month',
  'last_three_months', 'last_six_months',
  'this_quarter', 'last_quarter',
  'this_fiscal_year', 'last_fiscal_year',
  'this_year', 'last_year',
];

export const specialFilterValues = [
  'filter_current_employee_current_team',
  'filter_current_employee_current_team_and_children',
  'filter_current_employee_current_team_and_descendant',
  'filter_current_employee_children',
  'filter_current_employee_descendant',
  'filter_current_employee_all_teams_same_management_chain',
  'filter_current_login_user',
  'except_current_login_user',
];

export const sortableOptions = {
  animation: 150,
  dragClass: 'sortable-drag',
  fallbackOnBody: true,
  ghostClass: 'sortable-ghost',
  group: 'nested',
  handle: '.item-drag',
  swapThreshold: 0.65,
};
export const statusValues = ['working', 'maternity_leave', 'absence', 'retire', 'fired', 'family_leave', 'nursing_leave', 'scheduled', 'other'];
export const notRetireOrFiredStatusValues = statusValues.filter(item => !['retire', 'fired'].includes(item));

export const scroll = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background-color: ${COLORS.grayAthens};
  }

  ::-webkit-scrollbar:horizontal {
    height: 4px;
    background-color: ${COLORS.grayAthens};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${COLORS.beBlackColor};
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
`;

export const hideScroll = css`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;
export const HTML_REGEX = /<.*>.*<\/.*>/;

export const URL_FORMAT = new RegExp('^(https?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

export const infoIcon = css`
  .fa-info-icon {
    border-radius: 50%;
    border: 1.5px solid ${COLORS.red};
    color: ${COLORS.red};
    font-size: 14px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 16px;
    box-sizing: content-box;

    &:before {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid ${COLORS.red};
      color: ${COLORS.dark};
      transition: unset !important;
    }

    &[data-balloon-pos='right']:after {
      background: white !important;
      border-top: 1px solid ${COLORS.red};
      border-left: 1px solid ${COLORS.red};
      width: 8px !important;
      height:8px !important;
      transform: rotate(-45deg) !important;
      left: 115% !important;
      top: 20% !important;
      transition: unset !important;
    }

    &[data-balloon-pos='left']:after {
      background: white !important;
      border-top: 1px solid ${COLORS.red};
      border-left: 1px solid ${COLORS.red};
      width: 8px !important;
      height:8px !important;
      transform: rotate(135deg) !important;
      right: 115% !important;
      top: 20% !important;
      transition: unset !important;
    }
  }
`;

export const IGNORE_ADD_COMMAS_COLUMNS = [
  'employee_number', 'sub_id', 'email', 'phone_number', 'hire_date',
  'main_team',
  'sub_team',
];

export const TIMELINE_TYPES = [
  'grade_histories', 'job_category_histories',
  'team_employees', 'employee_status_histories',
  'employment_type_histories', 'employee_number_histories',
  'email_histories',
];

/* eslint-disable sort-keys */
export const NUMBER_UNITS = {
  千円: 1000,
  万円: 10000,
  百万: 1000000,
  Thousand: 1000,
  Million: 1000000,
};
/* eslint-enable sort-keys */

export const selectTypesIsEmployee = {
  employee: true,
  reviewer: true,
};
