import { connect } from 'react-redux';
import Search from '../../components/Search';
import {
  fetchEmployees,
  cleanStore,
} from './actions';

// TODO: implement mapStateToProps and mapActionsToProps after API ready
const mapStateToProps = state => {
  const search = state.resources.search || state.search || {};
  return {
    employees: search.employees,
    isFetching: search.isFetching,
  };
};

const mapActionsToProps = dispatch => ({
  fetchEmployees: dataSet => dispatch(fetchEmployees(dataSet)),
  cleanStore: () => dispatch(cleanStore()),
});

export default connect(mapStateToProps, mapActionsToProps)(Search);
