import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import { formatDate } from './dateUtils';

const NUMBER_FORMAT = /^[+-]?[\d,]*\.?\d*$/;

const tryConvertToNumber = value => {
  if (value && typeof (value) === 'string' && NUMBER_FORMAT.test(value)) {
    const strValue = value.replaceAll(',', '');
    return isNumber(strValue) ? Number(strValue) : strValue;
  }
  return value;
};

export const comparatorText = value => (value === 'lt' ? '<' : '<=');

export const buildSubmitFilterGroupFormData = ({ filterGroup }) => {
  const children = (filterGroup?.children || []).map(filter => {
    const {
      compareType, customType, calculationMethod, selectedValues, inputValue, minRange, maxRange, period = [],
      relativeDateCustomStartRange, relativeDateCustomEndRange,
      value, isIncludedEmployeesInChildrenTeam, isIncludedSubTeamEmployees, selectedDataset,
    } = filter;
    if (filter.children) {
      return buildSubmitFilterGroupFormData({ filterGroup: filter });
    }

    if (!filter.value) { return; }

    let values = selectedValues;
    if (Array.isArray(values)) {
      values = (selectedValues || []).map(item => item?.value || item);
    }

    const filterData = {
      value: filter.value,
      label: filter.label,
      min_range: isDate(minRange) ? formatDate(minRange) : tryConvertToNumber(minRange),
      max_range: isDate(maxRange) ? formatDate(maxRange) : tryConvertToNumber(maxRange),
      comparators: filter.comparators || ['lte', 'lte'],
      single_comparator: filter.singleComparator || '=',
      selected_values: selectedValues,
      input_value: inputValue,
      custom_type: customType?.value || customType,
      calculation_method: calculationMethod?.value || calculationMethod || 'average',
      date: formatDate(filter.date) || '',
      start_date: formatDate(period[0]) || '',
      end_date: formatDate(period[1]) || '',
      filter_type: filter.filterType || filter.filter_type || filter.filterOption?.filter_type,
      input_type: filter.inputType || filter.input_type || filter.filterOption?.input_type,
      compare_type: compareType?.value || compareType,
      operators: filter.operators,
      date_filter_type: filter.dateFilterType?.value || filter.dateFilterType,
      period: (period || []).map(item => formatDate(item)),
      num_day_type: filter.numDayType,
      relative_date_value: filter.relativeDateValue,
      num_day: filter.numDay,
      selected_period: filter.selectedPeriod?.value || filter.selectedPeriod || 'this_month',
      relativeDateCustomStartRange,
      relativeDateCustomEndRange,
    };

    if (value === 'team_by_employee_data') {
      Object.assign(filterData, {
        is_included_employees_in_children_team: isIncludedEmployeesInChildrenTeam,
        is_included_sub_team_employees: isIncludedSubTeamEmployees,
        filter_group: buildSubmitFilterGroupFormData({ filterGroup: filter.filterGroup }),
        selected_dataset: selectedDataset,
      });
    }

    return filterData;
  }).filter(Boolean);
  return {
    ...filterGroup,
    children,
  };
};
