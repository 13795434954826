import {
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEES_SUCCESS,
  CLEAN_STORE,
} from './constants';

const initialState = {
  isFetching: false,
  employees: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_EMPLOYEES_SUCCESS: {
      const {
        total,
        per_page,
        total_pages,
        employees,
      } = action.payload.data;
      return {
        ...state,
        isFetching: false,
        employees,
        total,
        totalPages: total_pages || Math.ceil(total / per_page) || 1,
      };
    }
    case CLEAN_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
