
import React from 'react';
import classNames from 'classnames';

const LoadingGrid = (props) => {
  const customBgColorClass = props.bgColorClass;
  const customGridColorClass = props.ldsColorClass;
  return (
    <div
      className={classNames(
        'd-flex justify-content-center align-items-center w-100',
        { [customBgColorClass]: customBgColorClass },
      )}
      style={{ height: '90vh', top: 0, left: 0 }}
    >
      <div
        className={classNames('lds-grid', {
          [customGridColorClass]: customGridColorClass,
        })}
      >
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

const LoadingSignal = (props) => {
  const customBgColorClass = props.bgColorClass;
  const customLdsColorClass = props.ldsColorClass;
  return (
    <div
      className={classNames(
        'd-flex justify-content-center align-items-center w-100 h-100',
        { [customBgColorClass]: customBgColorClass },
      )}
    >
      <div
        className={classNames('lds-signal', {
          [customLdsColorClass]: customLdsColorClass,
        })}
      >
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export { LoadingGrid, LoadingSignal };
