import axios from 'axios';
import toastr from 'utils/reactReduxToastr';
// import store from '../store/index';
import i18n from 'locale/i18n';

function _createXhr() {
  const instance = axios.create();
  instance.interceptors.request.use(
    config => config,
    error => Promise.reject(error)
    ,
  );

  instance.interceptors.response.use(
    response => response,
    error => {
      let {
        data: { message },
      } = error.response;

      if (message && Array.isArray(message)) {
        message = message.join(',');
      }
      if (message) {
        toastr.error(message);
      } else {
        toastr.error(i18n.t('common.fail'));
      }

      return Promise.reject(error);
    },
  );

  return instance;
}

export default class Api {
  static get axiosInstance() {
    return _createXhr();
  }

  static get(endpoint, options = {}) {
    return this.axiosInstance.get(endpoint, options);
  }

  static post(endpoint, options) {
    return this.axiosInstance.post(endpoint, options);
  }

  static put(endpoint, options, headers) {
    return this.axiosInstance.put(endpoint, options, headers);
  }

  static patch(endpoint, options) {
    return this.axiosInstance.patch(endpoint, options);
  }

  static delete(endpoint, options) {
    return this.axiosInstance.delete(endpoint, options);
  }
}
