import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import ImageVersion from '../../common/ImageVersion';

const NewUserAvatar = props => {
  const {
    size, url, alt, borderClassName = 'border', onClick, onMouseDown = () => {},
    version = 'small',
  } = props;

  let innerImage = (
    <FontAwesomeIcon
      icon={faUser}
      transform='shrink-3'
      style={{
        width: '100%',
        height: '100%',
        marginTop: '20%',
        color: 'grey',
      }}
    />
  );

  if (url) {
    innerImage = <ImageVersion style={{ width: size, height: size, objectFit: 'cover' }} src={url} alt={alt || 'inner'} version={version} draggable='false' />;
  }
  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
      className={`user-avatar ${borderClassName} justify-content-center align-items-center ${
        onClick ? 'cursor-pointer' : ''
      }`}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {innerImage}
    </div>
  );
};

export default NewUserAvatar;
