import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSearch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { styles } from './styles';

export const Desktop = ({
  searchText, placeholder, onKeyDown, onSubmit, onChange, Logo, Name, onFocus, isFocus, onClickName,
  isViewAs, fullName, t, companyName, isAdmin, notificationCounter,
}) => (
  <div className={styles.desktop}>
    <a className='logo' href='/admin'>{Logo()}</a>
    {isViewAs && (
      <>
        <div className='view-as'>{t('components.consoles.view_as_employee', { name: fullName, company: companyName })}</div>
        <div
          className='view-as ml-3 pointer'
          onClick={() => fetch('/logout-view-as').then(async res => { window.location.href = (await res.json()).redirect_path; })}
        >
          {t('components.consoles.view_as_exit')}
        </div>
      </>
    )}
    <span className='input'>
      <input readOnly={!isFocus} value={searchText} onKeyDown={onKeyDown} onChange={onChange} placeholder={placeholder} onFocus={onFocus} />
    </span>
    <span className='search-btn pointer'>
      <FontAwesomeIcon
        onClick={onSubmit}
        icon={faSearch}
      />
    </span>
    {isAdmin && (
      <a href='/admin/logs' className='admin-log'>
        <FontAwesomeIcon icon={faBell} className={classNames('pointer', { active: notificationCounter > 0 })} />
        {notificationCounter > 0 && <div className='notification-counter'>{notificationCounter}</div>}
      </a>
    )}
    <Name onClick={onClickName} />
  </div>
);
