import * as type from './constants';

export const fetchEmployees = payload => ({
  type: type.FETCH_EMPLOYEES,
  payload,
});

export const fetchEmployeesSuccess = payload => ({
  type: type.FETCH_EMPLOYEES_SUCCESS,
  payload,
});

export const fetchEmployeesFailure = error => ({
  type: type.FETCH_EMPLOYEES_FAILURE,
  error,
});

export const cleanStore = () => ({
  type: type.CLEAN_STORE,
});
