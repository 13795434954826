import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import searchReducer from '../../../shared-libs/containers/Search/reducer';

const globalReducer = combineReducers({
  resources: combineReducers({
  }),
  form: formReducer,
  toastr: toastrReducer,
  search: searchReducer,
});

export default globalReducer;
