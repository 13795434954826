import { toastr } from 'react-redux-toastr';
import React from 'react';
import errorIcon from '../images/custom_icon_error.png';

const toastrOptions = {
  timeOut: 2500,
  icon: <img src={errorIcon} alt='error' />,
};

export default {
  error: message => toastr.error(message, toastrOptions),
  alert: message => toastr.error(message, toastrOptions),
  notice: message => toastr.success(message),
  success: message => toastr.success(message),
  confirm: message => toastr.confirm(message),
};
