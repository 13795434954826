import {
  put, takeLatest,
} from 'redux-saga/effects';
import Api from '../../utils/api';
import * as actions from './actions';

import * as constants from './constants';

function* fetchEmployeesData(action) {
  const { params = {} } = action.payload || {};
  try {
    const { data } = yield Api.post(constants.EMPLOYEES_SEARCH_ENDPOINT, params);

    yield put(actions.fetchEmployeesSuccess({ data }));
  } catch (error) {
    console.error(error);
    yield put(actions.fetchEmployeesFailure(error));
  }
}

function* saga() {
  yield takeLatest(constants.FETCH_EMPLOYEES, fetchEmployeesData);
}

export default [saga];
