/* eslint-disable no-alert */

import React from 'react';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import store from 'store';

import Search from '@be/shared-libs/containers/Search';

const SearchComponent = props => (
  <Provider store={store}>
    <Search
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </Provider>
);

export default SearchComponent;
