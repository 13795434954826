import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

let resources = {};
try {
  resources = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('i18nextResources')) || {} : {};
} catch (e) {
  resources = {};
}

if (Array.isArray(resources)) { resources = {}; }

let debug = true;
if (typeof window !== 'undefined' && (localStorage.getItem('disable-i18n-debug') || process.env.NODE_ENV === 'test')) {
  debug = false;
}
i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    fallbackLng: ['ja', 'en'],
    debug,

    keySeparator: '.',

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/api/v1/admin/languages/translations.json?locale={{lng}}',
      parse(data, path) {
        const language = path.split('locale=')[1];
        const translation = JSON.parse(data.replace(/%{(\w+)}/g, '{{$1}}'));
        resources[language] = { translation };
        if (typeof window !== 'undefined') {
          window.localStorage.setItem('i18nextResources', JSON.stringify(resources));
        }
        return translation;
      },

    },
    initImmediate: false,
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: false,
    },
  });
if (Object.keys(resources || {}).length > 0) {
  i18next.addResourceBundle('ja', 'translation', (resources.ja || {}).translation);
  i18next.addResourceBundle('en', 'translation', (resources.en || {}).translation);
}

export default i18next;
export const t = i18next.t.bind(i18next);
