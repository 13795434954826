import { css } from 'emotion';
import { COLORS } from '../../../client/src/utils/constants';

const adminLog = css`
  .admin-log {
    position: relative;
    margin-left: 0.5rem;
    display: flex;

    svg {
      color: ${COLORS.graySantas};
      margin-top: auto;
      margin-bottom: 4px;
    }

    .active {
      color: ${COLORS.blueDodger};
    }

    .notification-counter {
      background-color: ${COLORS.red};
      border-radius: 10px;
      border: none;
      color: ${COLORS.white};
      font-size: 10px;
      left: calc(100% - 8px);
      line-height: 10px;
      min-width: 16px;
      padding: 3px;
      position: absolute;
      text-align: center;
      top: 2px;
    }
  }
`;

export const styles = {
  desktop: css`
    color: #A3A6B4;
    flex: 1;
    display: flex;
    line-height: 2rem;
    justify-content: flex-end;

    .view-as {
      margin-left: 16px;
      background-color: ${COLORS.sanMarino};
      color: ${COLORS.white};
      padding: 0 10px;
      font-size: 10px;
    }

    .search-btn {
      display: flex;

      svg {
        margin-top: auto;
        margin-bottom: 4px;
      }
    }

    .input {
      margin-left: auto;

      input {
        width: 100%;
        border: unset;
        font-size: 12px;
        border-bottom: 1px solid #A3A6B4;
        padding: 2px 0;
        line-height: 12px;

        &:focus{
          outline: none;
        }
      }
    }
    .username {
      color: black;
      padding-left: 0.5rem;
      padding-top: 8px;
      line-height: 24px;
      cursor: pointer;
    }

    ${adminLog}
  `,
  mobile: isOpen => css`
    color: #A3A6B4;
    flex: 1;
    display: flex;
    line-height: 2rem;
    padding-right: 0.5rem;

    .search-btn, .input {
      margin: ${isOpen ? '0 auto 0 0' : '0 0 0 auto'};
    }

    .search-btn {
      display: flex;

      svg {
        margin-top: auto;
        margin-bottom: 6px;
      }
    }

    .input {
      ${isOpen ? 'margin-left: .5rem;' : 'margin: 0;'}
      transition: all 0.25s ease-in;
      flex: ${isOpen ? 1 : 0};
      input {
        width: 100%;
        border: unset;
        font-size: 12px;
        border-bottom: 1px solid #A3A6B4;
        padding: 2px 0;
        line-height: 12px;
        box-sizing: content-box;

        &:focus{
          outline: none;
        }
      }
    }

    ${adminLog}

    .admin-log {
      svg {
        margin-bottom: 6px;
      }
    }
  `,

  main: css`
    flex-grow: 1;
    position: relative;
    z-index: 1;

    .header-employee-search-result {
      position: absolute;
      right: 10px;
      top: calc(100% + 20px);
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 18px 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 264px;
      z-index: 1;

      .employees {
        display: flex;
        flex-direction: column;
        max-height: calc(32px * 5 + 24px * 4);
        overflow-y: auto;
      }
      .employee-wrap {
        padding: 0 20px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.075);
        }
      }
      .employee {
        display: flex;
        gap: 16px;
        margin: 10px 0;

        .info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: calc(100% - 32px - 16px);

          div {
            font-size: 10px;
            line-height: 14px;
            color: #A4A6B4;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .fullname {
            font-size: 14px;
            line-height: 14px;
            color: #182C33;
          }
        }
      }

      .link-to-employe-search {
        padding: 5px;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 14px;
        width: 100%;
        text-align: center;

        a {
          color: #3597FC;
          text-decoration: none;
        }
      }
    }

    .employee-search-result-no-record {
      text-align: center;
      padding-top: 20px;
    }

    @media (max-width: 767px) {
      .header-employee-search-result {
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  `,
};
